<template>
  <base-section id="contact" class="secondary">
    <v-responsive class="mx-auto" max-width="1350">
      <v-container fluid>
        <v-row>
          <v-col
            v-for="card in cards"
            :key="card.title"
            cols="12"
            sm="4"
            md="4"
          >
            <base-info-card align="center" dark v-bind="card" />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
export default {
  name: "SectionFeatures",

  data: () => ({
    cards: [
      {
        icon: "mdi-linkedin",
        title: "LinkedIn",
        html:
          '<a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/jorissuilen/">LinkedIn profiel</a>'
      },
      {
        icon: "mdi-email-outline",
        title: "E-mail",
        html: '<a href="mailto:info@suilen-it.nl">info@suilen-it.nl</a>'
      },

      {
        icon: "mdi-office-building-marker",
        title: "Bedrijfsgegevens",
        html: "<span>KVK: 82502609</span>"
      }
    ]
  })
};
</script>
